import dp from '../Assets/profile1.jpeg';
import bg from '../Assets/lovepik-business-people-sitting-at-their-desks-png-image_400427230_wh1200.png';
import bg2 from '../Assets/bg2.jpg';
import bg3 from '../Assets/bg3.webp';
import an1 from '../Assets/profile.jpg';
import tax from '../Assets/taxation.jpg';
import imex from '../Assets/importexport1.jpg';
import impo from '../Assets/importexport.webp';

export default{
    dp,
    bg,
    bg2,
    bg3,
    an1,
    tax,
    imex,
    impo
}