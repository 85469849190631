import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Footer from './Components/Footer/Footer';
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'
import BackToTop from './Components/Backtotop/Backtotop';

function App() {
  
  useEffect(() => {
    Aos.init();
  }, [])

  return (
    <div className="App">
     <Navbar/>
     <Home/>
     <BackToTop/>
     <Footer/>
    </div>
  );
}

export default App;
