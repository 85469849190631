import React, { useRef, useState } from "react";
import "./Contact.scss";
import emailjs from "@emailjs/browser";

const Home = () => {
  const formref = useRef();
  const [done, setdone] = useState(null);

  const hsubmit = (e) => {
    e.preventDefault();

    const lavya = new FormData(formref.current);
    const userFirstname = lavya.get("firstname");
    const userLastname = lavya.get("lastname");
    const userEmail = lavya.get("email");
    const userMessage = lavya.get("message");

    emailjs
      .sendForm(
        "service_vvf2rx9",
        "template_48fz1na",
        formref.current,
        "_AG_Y7IzyRcc1oo0Z"
      )
      .then((result) => {
        console.log("Registration email sent:", result.text);

        if (hsubmit) {
          const userData = {
            name: userFirstname,
            name: userLastname,
            email: userEmail,
            message: userMessage,
          };
          emailjs
            .send(
              "service_vvf2rx9",
              "template_cd9xfn8",
              userData,
              "_AG_Y7IzyRcc1oo0Z"
            )
            .then((result) => {
              console.log("Thank you email sent:", result.text);
            })
            .catch((error) => {
              console.error("Error sending email:", error.text);
            });
        } else {
          console.error("Error: The user email address is empty");
        }
      })
      .catch((error) => {
        console.error("Error sending registeration email:", error.text);
      });

    formref.current.reset();
    setdone(true);

    setTimeout(() => {
      setdone(false);
    }, 5000);
  };

  return (
    <div   className="contact">
      <form  ref={formref} onSubmit={hsubmit}>
        <div className="group_form">
          <label htmlFor="name">Your Name</label>
          <input
            type="text"
            name="name"
            id="name"
            className="input_form"
            required
          />
        </div>
        <div className="line"></div>
        <div className="form-group">
          <label htmlFor="email">Your Email</label>
          <input
            type="email"
            name="email"
            id="email"
            className="form_input"
            required
          />
        </div>
        <div className="line"></div>
        <div className="form-group">
          <label htmlFor="message">Wite your message</label>
          <textarea 
            name="message" 
            className="form_nput" />
        </div>
        <div className="line"></div>
        <button type="submit" className="button_submit">
          Send
        </button>
        {done && <div className="done">Email Sent</div>}
      </form>
    </div>
  );
};

export default Home;
