import React, { useEffect, useState } from 'react'
import './Skillbar.css';

const Bar = ({skill, percentage}) => {
    const [progress, setprogress] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => setprogress(percentage), 500)
        return() => clearTimeout(timeout);
    }, [percentage]);

    return(
        <div className="skill_bar">
            <div className="skilltext">{skill}</div>
            <div className="progress_bar">
                <div className="progress" style={{width: `${progress}%`}}>
                    <span className="progresstext">{progress}%</span>
                </div>
            </div>
        </div>
    )
}

const Skillbar = () => {
    const skills =[
        {skill: "Garments", percentage: 100},
        {skill: "Groceries", percentage: 100},
        {skill: "Miscelenious Items ", percentage: 100},
    ]

  return (
    <div className='skill_container'>
        {skills.map((item, index) => (
            <Bar key={index} skill={item.skill} percentage={item.percentage} />
        ))}
    </div>
  )
}

export default Skillbar