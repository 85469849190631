import React from "react";
import images from "../../Data/Images";
import "./Home.scss";
import Skillbar from "../../Common/Skillbar/Skillbar";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import { FaLocationArrow } from "react-icons/fa";
import { MdGridOn } from "react-icons/md";
import { IoLogoHtml5 } from "react-icons/io5";
import { GrPersonalComputer } from "react-icons/gr";
import { FaStar } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { IoEarth } from "react-icons/io5";
import Contact from "../Contact/Contact";
import { FaAddressCard } from "react-icons/fa";

const Home = () => {
  return (
    <div className="home">
      <div className="box_one">
        <div data-aos="fade-right" className="left">
          <img src={images.tax} alt="photo" />
        </div>
        <div data-aos="fade-left" className="right">
          <div className="first">
            <span>welcome to my website</span>
          </div>
          <div className="second">
            <p>
              Hello, I am <span>Amandeep Narang</span>
            </p>
          </div>
          <div className="third">
            <span>
              I'm a taxation professional as well as i am business professional
              having 25 years of business experience. <br />
              <h3>
                (A) PROFESSONAL TAXATION SERVICES <br /> <br />(B) IMPORT AND
                TAXATION SERVICES
              </h3>{" "}
              <br />
              {/* 1. e-filling of INCOME TAX Returns<br />

2. e-filling of GST Returns<br />

3. e-filling of TDS Returns<br />

4. PAN Card ( NEW - CORRECTION - LOST )<br />

5. DSC ( Digital Signature Certificate )<br />

6. GST ( Goods & Services Tax )<br /> */}
            </span>
          </div>
          {/* <div className="four">
            <a href="">Get Started</a>
          </div> */}
        </div>
      </div>
      <div id="tax" className="twotitle">
        <span>taxation services</span>
      </div>
      <div className="box_two">
        <div data-aos="fade-right" className="left">
          <div className="first">
            <span>As PROFESSONAL TAXATION SERVICES</span>
          </div>
          <div className="second">
            <span>We provide below type of Services</span>
          </div>
          <div className="third">
            1. e-filling of INCOME TAX Returns
            <br />
            2. e-filling of GST Returns
            <br />
            3. e-filling of TDS Returns
            <br />
            4. PAN Card ( NEW - CORRECTION - LOST )<br />
            5. DSC ( Digital Signature Certificate )<br />
            6. GST ( Goods & Services Tax )<br />
          </div>
        </div>
        <div data-aos="fade-left" className="right">
          <div data-aos="fade-right" className="left_two">
            <img src={images.an1} alt="photo" />
          </div>
          <div
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            className="right_two"
          >
            <div className="first">
              <span>My Skills</span>
            </div>
            <div className="second">
              <span>
                Taxation Expert <br />
              </span>
            </div>
            <div className="third">
              <Skillbar />
            </div>
          </div>
        </div>
      </div>

      <div className="box_three">
        <div data-aos="flip-down" className="first">
          <div className="one">
            <a href="">
              <HiMiniComputerDesktop />
            </a>
          </div>
          <div className="two">
            <span>Income Tax Returns</span>
          </div>
          <div className="three">
            <p>
              individual Returns
              <br /> Partnership Firms <br />
              Corporate Returns
            </p>
          </div>
        </div>
        <div data-aos="flip-down" className="second">
          <div className="one">
            <a href="">
              <FaLocationArrow />
            </a>
          </div>
          <div className="two">
            <span>TDS Returns</span>
          </div>
          <div className="three">
            <p>
              Individual TDS Returns <br /> <br /> Corporate TDS Returns
            </p>
          </div>
        </div>
        <div data-aos="flip-down" className="third">
          <div className="one">
            <a href="">
              <MdGridOn />
            </a>
          </div>
          <div className="two">
            <span>GST Returns</span>
          </div>
          <div className="three">
            <p>
              GST Returns : Indivisually <br />
              <br />
              GST Returns : Cooperates
            </p>
          </div>
        </div>
        <div data-aos="flip-down" className="fourth">
          <div className="one">
            <a href="">
              <FaAddressCard />
            </a>
          </div>
          <div className="two">
            <span>Pan Card</span>
          </div>
          <div className="three">
            <p>
              New Pan Card <br />
              <br />
              Correction of Pan Card
            </p>
          </div>
        </div>
      </div>

      {/* start */}
      <div id="hello" className="twotitle">
        <span>IMPORT & EXPORT SERVICES</span>
      </div>

      <div className="box_two">
        <div data-aos="fade-right" className="left">
          <div data-aos="fade-left" className="first">
            <span>As IMPORTER AND EXPORTER SERVICES</span>
          </div>
          <div className="second">
            <span> Our services are provided in the following countries</span>
          </div>
          <div className="third">
            1. Canada
            <br />
            2. USA
            <br />
            3. Australia
            <br />
            4. UK
            <br />
            5. New Zealand
            <br />
            6. Europe
            <br />
          </div>
        </div>
        <div data-aos="fade-left" className="right">
          <div
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            className="left_two"
          >
            <img src={images.an1} alt="photo" />
          </div>
          <div className="right_two">
            <div className="first">
              <span>Services Type</span>
            </div>
            <div className="second">
              <span>Following up our skills</span>
            </div>
            <div className="third">
              <Skillbar />
            </div>
          </div>
        </div>
      </div>

      {/* end */}
      {/* <div className="box_four">
        <div className="right">
          <div className="second">
            <div className="one">
              <a href="">
                <GrPersonalComputer />
              </a>
            </div>
            <div className="two">
              <div className="one_one">
                <p>
                  Coding Eggs <span>2023 Jan 05</span>
                </p>
              </div>
              <div className="one_two">
                <p>
                  From January 2023 to January 2024, I’ve worked as a Junior
                  Front-End Developer at Coding Eggs, where I’ve been
                  responsible for developing and maintaining user-friendly web
                  interfaces.
                </p>
              </div>
            </div>
          </div>
          <div className="second">
            <div className="one">
              <a href="">
                <GrPersonalComputer />
              </a>
            </div>
            <div className="two">
              <div className="one_one">
                <p>
                  Coding Eggs <span>2024 Apr 01</span>
                </p>
              </div>
              <div className="one_two">
                <p>
                  In January 2024, I was promoted to Senior Front-End Developer,
                  taking on the responsibility of leading the development and
                  optimization of front-end applications.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="box_five">
        <div className="left">
          <div className="first">
            <div className="one">
              <span>My Education</span>
            </div>
            <div className="two">
              <p>In cursus orci non ipsum gravida dignissim</p>
            </div>
          </div>
          <div className="second">
            <div className="one">
              <a href="">
                <GrPersonalComputer />
              </a>
            </div>
            <div className="two">
              <div className="one_one">
                <p>
                  Grade 12th <span>2022 Apr-2023 Mar</span>
                </p>
              </div>
              <div className="one_two">
                <p>
                  I've taken Commerce stream in 12th and in that I've achieved
                  85.8% marks in exams.
                </p>
              </div>
            </div>
          </div>
          <div className="second">
            <div className="one">
              <a href="">
                <GrPersonalComputer />
              </a>
            </div>
            <div className="two">
              <div className="one_one">
                <p>
                  Grade 10th <span>2020 Apr-2021 Mar</span>
                </p>
              </div>
              <div className="one_two">
                <p>
                  I've taken Commerce stream in 10th and in that I've achieved
                  64.6% marks in exams.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <img src={images.an1} alt="image" />
        </div>
      </div> */}
      <div className="box_six">
        <div className="first">
          {/* An Indian Group With Global Outlook */}
          {/* <a href=""><FaStar />
          </a> */}
        </div>
        <div className="second">
          {/* <span>
          An Indian Group With Global Outlook, <br />{" "}
            
          </span> */}
        </div>
        <div id="contact" className="third">
          {/* <p>
          We, at FINDUS.NET.IN, welcome you in joining us to share the long and fruitful journey extending to 17 long years. <br /> FINDUS.NET.IN, which has truly lived up to its name. FINDUS.NET.IN having an Importer & exporter License  in which  <br /> 
          company provides different types of services to its clients like Taxation Services & other Services committed to provide  <br /> 
          high quality products  subjected to stringent quality controls, to the consumer and sustain their 
          confidence. <br /> Towards this commitment, <br /> FINDUS.NET.IN is expanding its projects
           with strong logistics infrastructure and competent manpower to achieve the object.
          </p> */}
        </div>
      </div>
      <div className="box_seven">
        <div data-aos="fade-right" className="left">
          <div className="first">
            <span>Say hello..</span>
          </div>
          <div className="second">
            <p>
              If you have any query or need any of our services, you can submit
              here.
            </p>
          </div>
          <div className="third">
            <Contact />
          </div>
        </div>
        <div data-aos="fade-left" className="right">
          <div className="first">
            <img src={images.an1} alt="image" />
          </div>
          <div className="second">
            <div className="one">
              <span>Visit my office</span>
            </div>
            <div className="two">
              <span>
                Main Market, Street No. 8, <br />
                Old Bishan Nagar, <br /> Patiala, Punjab
              </span>
            </div>
            <div className="three">
              <span>Contact</span>
            </div>
            <div className="four">
              <a href="">
                <FaPhoneAlt />
              </a>
              <p>+91 9914540684</p>
            </div>
            <div className="four">
              <a href="">
                <IoIosMail />
              </a>
              <p>info@findus.net.in</p>
            </div>
            <div className="four">
              <a href="">
                <IoEarth />
              </a>
              <p>Amandeep Narang </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
