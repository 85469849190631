import React, { useState } from "react";
import "./Navbar.css";
import { GiCrossMark, GiHamburgerMenu } from "react-icons/gi";

const Navbar = () => {
  const [mobilemenu, setmobilemenu] = useState(false);

  return (
    <div class="navbar">
      <div className="logo">
        <span>FINDUS</span>
        <span className="lavya">.NET.IN</span>
      </div>
      <div className="nav_links">
        <ul className="navbar_links">
          <li>
            <a href="#">HOME</a>
          </li>
          <li>
            <a href="#tax">TAXATION</a>
          </li>
          <li>
            <a href="#hello">IMPORT&EXPORT</a>
          </li>
        
          <li>
            <a href="#contact">CONTACT</a>
          </li>
        </ul>
      </div>

      <div className="navbar_smallscreen">
        <GiHamburgerMenu
          className="navbar_menu"
          onClick={() => {
            setmobilemenu(true);
          }}
        />
        {mobilemenu && (
          <div className="navbar_smallscreen_overlay flex__center slide-bottom">
            <GiCrossMark
              className="overlay_close"
              onClick={() => {
                setmobilemenu(false);
              }}
            />
            <div className="navbar_mobile_links">
              <ul className="nav_mobile_links">
                <li>
                  <a href="/">HOME</a>
                </li>
                <li>
                  <a href="#education">EDUCATION</a>
                </li>
                <li>
                  <a href="#projects">PROJECTS</a>
                </li>
                <li>
                  <a href="#skills">SKILLS</a>
                </li>
                <li>
                  <a href="#contact">CONTACT</a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
