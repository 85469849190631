import React from "react";
import "./Footer.scss";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaGooglePlusG } from "react-icons/fa";
import { FaBasketballBall } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="first">
        <p>
          Copyright © 2025 Findus Dot Net | Designed by <a href="https://codewithlavya.com/">Lavya Narang</a>
        </p>
      </div>
      <div className="second">
        <a href="">
          <FaFacebookF />
        </a>
        <a href="">
          <FaTwitter />
        </a>
        <a href="">
          <FaGooglePlusG />
        </a>
        <a href="">
          <FaBasketballBall />
        </a>
        <a href="">
          <FaLinkedinIn />
        </a>
      </div>
    </div>
  );
};

export default Footer;
